<template>
  <a-modal
    :title="title"
    width="1000px"
    :visible="visible"
    @cancel="handleCancel"
    :maskClosable="false"
    :keyboard="false"
    :footer="null"
    class="select-mater-dialog"
  >
    <div class="dialog-content">
      <div class="search-box">
        <a-form-model ref="form" :model="form" class="search_form page-form-search" labelAlign="left" layout="inline">
          <a-form-model-item label="培训类别">
            <a-cascader
              style="width: 170px"
              :options="cateOption"
              placeholder="请选择培训类别"
              v-model="form.categoryId"
              :show-search="{ filter: filterOption }"
              allowClear
              :fieldNames="{
                label: 'name',
                value: 'id',
                children: 'list',
              }"
            />
          </a-form-model-item>
          <a-form-model-item label="资料类型">
            <a-select v-model="form.type" style="width: 170px" placeholder="请选择资料类型" allowClear>
              <a-select-option v-for="item in typeOption" :key="item.value" :value="item.value" :label="item.label">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="资料名称">
            <a-input v-model="form.title" placeholder="请输入资料名称" allowClear style="width: 170px" />
          </a-form-model-item>
        </a-form-model>
        <div class="form_btn_box">
          <a-button type @click="handleReset">重置</a-button>
          <a-button type="primary" style="margin-left: 12px" @click="handleSearch">搜索</a-button>
        </div>
      </div>
      <div class="table-all-box" style="margin-bottom: 12px">
        <a-button type="primary" @click="handleChangeAll('add')" :disabled="!selectRows.length">批量添加</a-button>
        <a-button type="primary" @click="handleChangeAll('delete')" :disabled="!selectRows.length">批量取消</a-button>
      </div>
      <div class="table-box">
        <p-table
          ref="table"
          row-key="id"
          :columns="columns"
          :sourceData="getDataApi"
          :scorll="{ y: 500 }"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        >
          <template slot="action" slot-scope="text, record">
            <a-popconfirm
              title="确认添加吗？"
              @confirm="handleChange(record, 'add')"
              ok-text="是"
              cancel-text="否"
              v-if="!selectRowIds.includes(record.id)"
            >
              <a-button type="link">添加</a-button>
            </a-popconfirm>
            <a-popconfirm
              title="确认取消吗？"
              @confirm="handleChange(record, 'delete')"
              ok-text="是"
              cancel-text="否"
              v-else
            >
              <a-button type="link">取消</a-button>
            </a-popconfirm>
          </template>
        </p-table>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { getDataByPage } from '@/api/train'

export default {
  name: 'SELECTMATERFORM',
  components: {},
  props: {
    cateOption: {
      type: Array,
      default: () => []
    },
    selectData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      form: {},
      visible: false,
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: 60,
          customRender: (t, r, i) => i + 1
        },
        {
          title: '资料名称',
          dataIndex: 'title',
          key: 'title',
          width: 500,
          ellipsis: true
        },
        {
          title: '培训类别',
          dataIndex: 'category',
          key: 'category'
        },
        {
          title: '资料类型',
          dataIndex: 'type',
          key: 'type',
          customRender: (t) => (t == 1 ? '文档' : '视频')
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: 80,
          scopedSlots: { customRender: 'action' }
        }
      ],
      typeOption: [
        { value: '1', label: '文档' },
        { value: '2', label: '视频' }
      ],
      selectRows: []
    }
  },
  computed: {
    title () {
      return `关联资料`
    },
    selectRowIds () {
      return this.selectData.map((item) => item.id)
    },
    selectedRowKeys () {
      return this.selectRows.map((item) => item.id)
    }
  },
  methods: {
    onSelectChange (selectedRowKeys, selectRows) {
      this.selectRows = selectRows
    },
    handleSearch () {
      this.$refs.table.initPage()
    },
    handleReset () {
      this.$set(this, 'form', { categoryId: [] })
    },
    getQuery () {
      const { title, type, categoryId = [] } = this.form
      return {
        type,
        title,
        categoryId: categoryId.length ? categoryId[categoryId.length - 1] : categoryId.join(',')
      }
    },
    getDataApi (params) {
      this.selectRows = []
      return getDataByPage({
        ...params,
        ...this.getQuery()
      })
    },
    filterOption (inputValue, path) {
      return path.some((option) => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    },
    createModel () {
      this.visible = true
      this.$nextTick(() => {
        this.$refs.table.refresh()
      })
    },
    handleCancel () {
      this.visible = false
      this.handleReset()
    },
    handleChange (rowItem, type) {
      this.$emit('change', [rowItem], type)
      this.$message.success(`${type === 'add' ? '添加' : '取消'}成功`)
    },
    handleChangeAll (type) {
      this.$emit('change', this.selectRows, type)
      this.$message.success(`${type === 'add' ? '添加' : '取消'}成功`)
      this.selectRows = []
    }
  }
}
</script>

<style lang="less">
.select-mater-dialog {
  .dialog-content {
    padding-bottom: 50px;
    .search-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
    }
  }
}
</style>
